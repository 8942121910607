import * as React from "react";
import { navigate } from "gatsby";
import { useEffect } from "react";
import { Country, State } from "country-state-city";
import { withPrefix } from "gatsby";
import Swal from 'sweetalert2';
import { Select, MenuItem } from '@mui/material';
import { useApplicationContext } from "../../../provider";
import { useDispatch, useSelector } from 'react-redux';
import { getOrderEstimate, selectCheckoutState } from "../../sagas/checkout/checkoutSlice";

export default function ShippingAddress({ valueA, valueB }) {
  const dispatch = useDispatch();
  const { isGettingOrderEstimate, isGettingOrderEstimateSuccess, isGettingOrderEstimateFailed, GetOrderEstimateResponse } = useSelector(selectCheckoutState);

  const [loading, setLoading] = React.useState(false);
  const allCountries = Country.getAllCountries();
  const [stateOptions, setStateOptions] = React.useState([]);
  const { checkoutState, setCheckoutState } = useApplicationContext();

  const [state, setState] = React.useState({
    firstname: "",
    lastname: "",
    address1: "",
    address2: "",
    city: "",
    state_code: "",
    state_name: "",
    country_code: "",
    country_name: "",
    zip: "",
  });
  useEffect(() => {
    const country = Country.getCountryByCode("US");
    setState({
      ...state,
      country_name: country.name,
      country_code: country.isoCode,
    });

    setStateOptions(State.getStatesOfCountry(country.isoCode));
  }, []);

  useEffect(() => {
    if (checkoutState.recipientAddress?.firstname) setState(checkoutState.recipientAddress);
  }, [checkoutState.recipientAddress])

  const handleContinue = async() => {
    //Validation
    if (!state.firstname || !state.lastname || !state.address1 || !state.city || !state.state_code || !state.zip) {
      Swal.fire({
        icon: 'error',
        text: 'Please enter your shipping address',
      })
      return;
    }

    const recipientAddress = {
      firstname: state.firstname,
      lastname: state.lastname,
      name: state.firstname + " "+ state.lastname,
      address1: state.address1,
      address2: state.address2,
      city: state.city,
      state_code: state.state_code,
      state_name: state.state_name,
      country_code: state.country_code,
      country_name: state.country_name,
      zip: state.zip,
      country: state.country_name,
      email: "",
    };
    setCheckoutState({ ...checkoutState, recipientAddress });

    //Updated Flow: we get the order estimation
    const finalItems = typeof window !== "undefined" && JSON.parse(localStorage.getItem("final-products") || "[]");

    const payload = {
      recipient:  recipientAddress,
      items: finalItems,
      external_id: new Date().getTime(),
      shipping: "STANDARD",
      gift: {
        subject: recipientAddress.firstname,
        message: "Have a nice day",
      },
      packing_slip: {},
    };

    dispatch({
      type: getOrderEstimate.type,
      payload,
    });
  };

  useEffect(() => {
    if (isGettingOrderEstimate) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isGettingOrderEstimate])

  useEffect(() => {
    if (isGettingOrderEstimateSuccess && GetOrderEstimateResponse) {
      if (GetOrderEstimateResponse.data?.custom_calculations) {
        localStorage.setItem("getOrderEst_response", JSON.stringify(GetOrderEstimateResponse));

        navigate("/payment-cart");
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Something went wrong, please try again',
          confirmButtonText: 'Retry',
        }).then(async(result) => {
          if (result.isConfirmed) handleContinue();
        })
      }
    } else if (isGettingOrderEstimateFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong, please try again',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) handleContinue();
      })
    }
  }, [isGettingOrderEstimateSuccess, isGettingOrderEstimateFailed, GetOrderEstimateResponse])
  
  const toogleBack = (e) => {
    e.preventDefault();
    navigate("/cart-listing");
  };

  const onChange = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading && (
        <div
          className="new_gif"
          style={{ display: "flex", top: 0, left: 0 }}
        >
          <img
            src={withPrefix("assets/img/green-little-balls.gif")}
            className="img-fluid mw_50"
            alt="loading"
          />
        </div>        
      )}

      <div className="col-lg-12 col-md-12">
        <div className="order-details">
          <div className="paymment-options">
            <div className="steps">
              <ul>
                <li className="active">Dot</li>
                <li>Dot</li>
                <li>Dot</li>
              </ul>
            </div>
            <div className="form-field">
              <h3>Shipping Address</h3>
              <form action="#" method="post">
                <ul>
                  <li className="sml-cl">
                    <input
                      id="name"
                      name="firstname"
                      type="text"
                      placeholder="First Name"
                      onChange={onChange}
                      value={state.firstname}
                      autoComplete="off"
                    />
                  </li>
                  <li className="sml-cl">
                    <input
                      id="name"
                      name="lastname"
                      type="text"
                      placeholder="Last Name"
                      onChange={onChange}
                      value={state.lastname}
                      autoComplete="off"
                    />
                  </li>
                  <li>
                    <input
                      id="addressline1"
                      name="address1"
                      type="text"
                      placeholder="Address Line 1"
                      onChange={onChange}
                      value={state.address1}
                      autoComplete="off"
                    />
                  </li>
                  <li>
                    <input
                      id="addressline2"
                      name="address2"
                      type="text"
                      placeholder="Address Line 2 (Optional)"
                      onChange={onChange}
                      value={state.address2}
                      autoComplete="off"
                    />
                  </li>
                  <li className="sml-cl">
                    <input
                      id="city"
                      name="city"
                      type="text"
                      placeholder="City"
                      onChange={onChange}
                      value={state.city}
                      autoComplete="off"
                    />
                  </li>
                  <li className="sml-cl">
                    <Select
                      value={state.state_code}
                      onChange={(e) => {
                        const selectedState = stateOptions.find((s) => s.isoCode === e.target.value);
                        setState({
                          ...state,
                          state_code: selectedState.isoCode,
                          state_name: selectedState.name,
                        });
                      }}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{ 
                        width: '100%', 
                        height: 52, 
                        borderRadius: '10px', 
                        borderColor: '#efefef',
                        fontFamily: '"Albert Sans", sans-serif',
                        fontWeight: '500',
                        fontSize: '16px',
                        color: '#10100a',
                        lineHeight: '20px',
                        '& .MuiInputBase-input': {
                          display: 'flex',
                          alignItems: 'center',
                        }
                      }}
                      autoComplete="off"
                    >
                      <MenuItem value="">Select State</MenuItem>
                      {stateOptions.map((state, index) => (
                        <MenuItem value={state.isoCode} key={index}>{state.name}</MenuItem>
                      ))}
                    </Select>
                  </li>
                  <li className="sml-cl">
                    <input
                      id="zip"
                      name="zip"
                      type="text"
                      maxLength={"5"}
                      placeholder="Zip Code"
                      onChange={onChange}
                      value={state.zip}
                      autoComplete="off"
                    />
                  </li>
                  <li className="sml-cl">
                    <Select
                      value={state.country_code}
                      onChange={(e) => {
                        const selectedCountry = allCountries.find((c) => c.isoCode === e.target.value);
                        setState({
                          ...state,
                          country_name: selectedCountry.name,
                          country_code: selectedCountry.isoCode,
                          state_code: '',
                          state_name: '',
                        });

                        setStateOptions(State.getStatesOfCountry(selectedCountry.isoCode));
                      }}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{ 
                        width: '100%', 
                        height: 52, 
                        borderRadius: '10px', 
                        borderColor: '#efefef',
                        fontFamily: '"Albert Sans", sans-serif',
                        fontWeight: '500',
                        fontSize: '16px',
                        color: '#10100a',
                        lineHeight: '20px',
                        '& .MuiInputBase-input': {
                          display: 'flex',
                          alignItems: 'center',
                        }
                      }}
                      autoComplete="off"
                    >
                      {allCountries.map((country, index) => (
                        <MenuItem value={country.isoCode} key={index}>{country.name}</MenuItem>
                      ))}
                    </Select>
                  </li>
                </ul>
                <div className="check-options">
                  <input
                    id="checkbox"
                    name="checkbox"
                    type="checkbox"
                    className="styled"
                  />
                  <label htmlFor="checkbox">Save as Billing Address</label>
                </div>
              </form>
            </div>
          </div>
          <div className="bottom-btns">
            <a href="#" onClick={toogleBack} className="btn">
              Back
            </a>{" "}
            <a href="#" onClick={handleContinue} className="btn btn-blue">
              Continue
            </a>
          </div>
        </div>
      </div>      
    </>

  );
}
